<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <template>
        <div class="card-body">
          <template slot="footer">
            <a-button key="back" @click="handleCancel">Return</a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="handleOk"
              >Submit</a-button
            >
          </template>
          <div>
            <div style="width: 50%; float: left">
              <div></div>
              <b>Désignation</b>
              <br />
              <a-input placeholder="Désignation" style="width: 80%"></a-input>
              <br /><br />
              <b>Fiche Enfant</b>
              <br /><br />
              <label style="margin-right: 10px">Création Fiche Enfant</label>
              <a-switch class="switch" default-checked @change="onChange" />
              <br /><br />
              <b>{{ $t("menu.scolarite") }}</b> <br /><br />
              <label style="margin-right: 10px">Inscription</label>
              <a-switch
                class="switch"
                default-checked
                @change="onChange"
              /><br />
              <label style="margin-right: 10px">Modification Inscription</label>
              <a-switch
                class="switch"
                default-checked
                @change="onChange"
              /><br />
              <label style="margin-right: 10px">Annulation Inscription</label>
              <a-switch
                class="switch"
                default-checked
                @change="onChange"
              /><br />
              <div>
                <label style="margin-right: 10px"
                  >Etat Impayée Scolarités</label
                >
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px">Présance Classe</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px">Anniversaire</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
              </div>
              <div></div>
              <label style="margin-right: 10px">Fiche Classe</label>
              <a-switch class="switch" default-checked @change="onChange" />
              <br /><br />
              <b>DECLARATION</b>
              <br /><br />
              <div>
                <label style="margin-right: 10px">Déclaration TVA</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px"
                  >Chiffre d'affaire Totale</label
                >
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px"
                  >Chiffre d'affaire Scolarité</label
                >
                <a-switch class="switch" default-checked @change="onChange" />
              </div>
            </div>
            <div style="width: 50%; float: right">
              <b>Activités</b>
              <br />
              <div>
                <label style="margin-right: 10px">Inscription</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px"
                  >Etat Impayées Activitées</label
                >
                <a-switch class="switch" default-checked @change="onChange" />
              </div>
              <div class="bottom"><b>Personnel</b></div>
              <br /><br />
              <div>
                <label style="margin-right: 10px">Personnel</label>
                <a-switch class="switch" default-checked @change="onChange" />
              </div>
              <br /><br />
              <b>Comptabilité</b>
              <br /><br />
              <div>
                <label style="margin-right: 10px">Réglement</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px"
                  >Journal Caisse Scolarité</label
                >
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px"
                  >Journal Caisse Services</label
                >
                <a-switch class="switch" default-checked @change="onChange" />
              </div>
              <div>
                <label style="margin-right: 10px"
                  >Journal Caisse Factures</label
                >
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px">Journal Caisse Recus</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px"
                  >Consulter Historique Réglement</label
                >
                <a-switch class="switch" default-checked @change="onChange" />
              </div>
              <div>
                <label style="margin-right: 10px">Dépenses</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px">Etat impayées</label>
                <a-switch
                  class="switch"
                  default-checked
                  @change="onChange"
                /><br />
                <label style="margin-right: 10px"
                  >Annulation Recue Paiement</label
                >
                <a-switch class="switch" default-checked @change="onChange" />
              </div>
              <br /><br />
              <b>Administration</b>
              <br /><br />
              <label style="margin-right: 10px">Administration</label>
              <a-switch
                class="switch"
                default-checked
                @change="onChange"
              /><br />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.switch {
  float: right;
  margin-right: 100px;
}
.bottom {
  margin-top: 7px;
}
</style>
